var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb15 mt20 payment-cash-on-delivery-info-container" },
    [
      _c("h4", { staticClass: "mt0" }, [
        _vm._v("\n    Payment Information\n  ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bg-cl-secondary px20 py20" }, [
        _c("p", [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t("You are going to pay for this order upon delivery.")
              ) +
              "\n    "
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }